<template>
  <div v-if="rasterLayers.length > 0">
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-divider
      class="pb-4"
    />
    <div class="d-flex justify-space-between">
      <meteogram-button
        v-if="isMeteogram"
        :meteogram-enabled="meteogramEnabled"
        :location-name="map.locationName"
        :meteogram-obj="meteogramObj"
        :map-enabled="mapEnabled"
        @toggle-meteogram-btn="$emit('toggle-meteogram-btn', $event)"
        @toggle-map-btn="toggleMapBtn()"
      />
      <strong v-else>
        {{ $t('ServiceCard.rasterLayer') }}
      </strong>
      <v-btn
        v-if="($route.params.id === 'Real-Time' || $route.params.id === 'Forecast') && showSelect"
        elevation="2"
        icon
        :disabled="disableRefreshButton"
        @click="initRefresh()"
      >
        <v-icon :class="rotateEffect">
          mdi-refresh
        </v-icon>
      </v-btn>
    </div>
    <div v-if="showSelect">
      <v-row
        v-if="$route.params.id === 'Real-Time' || $route.params.id === 'Forecast'"
        class="ma-0"
      >
        <v-col
          cols="10"
          md="11"
          class="pl-0"
        >
          <v-select
            v-model="selectedRasterLayer"
            :disabled="isRefreshing"
            :attach="`.card${serviceCardId}`"
            :items="rasterLayers"
            :item-text="item =>`${$t(`${item.ws_name}:${item.layer_name}_serviceID:${serviceCardId}.label`)}${$t(`${item.ws_name}:${item.layer_name}_serviceID:${serviceCardId}.udm`)}`"
            item-color="RMprimary"
            color="RMprimary"
            return-object
            solo
            :class="`card${serviceCardId} pt-4`"
            @change="$emit('reset-min-max-step'); removePreviousLayer(); openRasterLayer(false, selectedRasterLayer); googleTag();"
          />
        </v-col>
        <v-col
          cols="2"
          md="1"
          class="pl-0"
        >
          <v-icon
            class="mt-7"
            :disabled="Object.keys(selectedRasterLayer).length === 0 || isRefreshing"
            @click="removeRasterSelected(); renderPlayer = false"
          >
            mdi-delete-forever
          </v-icon>
        </v-col>
      </v-row>
      <v-row
        v-else
        class="ma-0"
      >
        <v-col
          cols="10"
          md="11"
          class="pl-0"
        >
          <v-select
            v-model="selectedRasterLayer"
            :attach="`.card${serviceCardId}`"
            :items="rasterLayers"
            :item-text="item =>`${$t(`${item.ws_name}:${item.layer_name}_serviceID:${serviceCardId}.label`)}${$t(`${item.ws_name}:${item.layer_name}_serviceID:${serviceCardId}.udm`)}`"
            item-color="RMprimary"
            color="RMprimary"
            return-object
            solo
            :class="`card${serviceCardId} pt-4`"
          />
        </v-col>
        <v-col
          cols="2"
          md="1"
          class="pl-0"
        >
          <v-icon
            class="mt-7"
            :disabled="Object.keys(selectedRasterLayer).length === 0"
            @click="removeRasterSelected()"
          >
            mdi-delete-forever
          </v-icon>
        </v-col>
      </v-row>
      <div v-if="$route.params.id === 'Archive' && !overlay">
        <service-card-archive
          v-if="renderArchiveDate"
          :selected-raster-layer="selectedRasterLayer"
          :raster-layers="rasterLayers"
          :service-card-id="serviceCardId"
          :animation-limit-range-array="animationLimitRangeArray"
          :animation-limit-range-array-policy="animationLimitRangeArrayPolicy"
          :animation-step-date-type="animationStepDateType"
          :animation-step="animationStep"
          :min-step="minStep"
          :max-step="maxStep"
          :is-animation="isAnimation"
          :is-table="isTable"
          :is-graph="isGraph"
          :service-name="serviceName"
          :layer-type="'RASTER'"
          :animation-time-array="animationTimeArray"
          :animation-empty-steps="animationEmptySteps"
          :animation-start-index="animationStartIndex"
          :no-features-found="noFeaturesFound"
          :no-wmts-features-found="noWmtsFeaturesFound"
          :table-chart-range-date="tableChartRangeDate"
          @set-animation-steps="setAnimationSteps($event)"
          @no-raster-features-found="noRasterFeaturesFound($event)"
          @closeRasterService="$emit('closeRasterService', serviceCardId)"
          @google-tag="googleTag()"
        />
      </div>
      <div v-if="$route.params.id === 'Forecast' && renderPlayer">
        <service-card-forecast
          :selected-raster-layer="selectedRasterLayer"
          :service-card-id="serviceCardId"
          :animation-limit-range-array="animationLimitRangeArray"
          :animation-limit-range-array-policy="animationLimitRangeArrayPolicy"
          :animation-step-date-type="animationStepDateType"
          :animation-step="animationStep"
          :min-step="minStep"
          :max-step="maxStep"
          :is-animation="isAnimation"
          :overlay="overlay"
          :service-name="serviceName"
          :layer-type="'RASTER'"
          :animation-time-array="animationTimeArray"
          :animation-empty-steps="animationEmptySteps"
          :animation-start-index="animationStartIndex"
          :no-features-found="noFeaturesFound"
          :no-wmts-features-found="noWmtsFeaturesFound"
          :table-chart-range-date="tableChartRangeDate"
          :check-policy-limits="checkPolicyLimits"
          @set-animation-steps="setAnimationSteps($event)"
          @reset-min-max-step="$emit('reset-min-max-step')"
          @set-refresh-interval="setRefreshInterval()"
          @no-raster-features-found="noRasterFeaturesFound($event)"
        />
      </div>
      <div v-if="$route.params.id === 'Real-Time'">
        <animation-player
          v-if="renderPlayer"
          :selected-raster-layer="selectedRasterLayer"
          :service-card-id="serviceCardId"
          :animation-limit-range-array="animationLimitRangeArray"
          :animation-limit-range-array-policy="animationLimitRangeArrayPolicy"
          :animation-step-date-type="animationStepDateType"
          :animation-step="animationStep"
          :min-step="minStep"
          :max-step="maxStep"
          :is-animation="isAnimation"
          :overlay="overlay"
          :service-name="serviceName"
          :layer-type="'RASTER'"
          :animation-time-array="animationTimeArray"
          :animation-empty-steps="animationEmptySteps"
          :animation-start-index="animationStartIndex"
          :no-features-found="noFeaturesFound"
          :no-wmts-features-found="noWmtsFeaturesFound"
          @no-raster-features-found="noRasterFeaturesFound($event)"
        />
      </div>
      <div v-if="($route.params.id === 'Forecast' || $route.params.id === 'Real-Time') && renderPlayer">
        <raster-layer-table-chart-buttons
          :raster-layers="rasterLayers"
          :selected-raster-layer="selectedRasterLayer"
          :min-step="minStep"
          :max-step="maxStep"
          :disable-table-chart-buttons="false"
          :is-table="isTable"
          :is-graph="isGraph"
          :is-animation="isAnimation"
          :service-name="serviceName"
          :table-chart-range-date="tableChartRangeDate"
          :is-daily-data="dailyData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { RASTER_BASE_URL } from '@/constants.js';
import { toLonLat } from 'ol/proj';
import { getFormattedDate, updatedDateWithTimeStep, timeToMilliseconds, dateUnit } from '@/utils.js';

export default {
  components: {
    ServiceCardForecast: () => import('@/components/layout/ServiceCardForecast'),
    AnimationPlayer: () => import('@/components/utils/AnimationPlayer'),
    ServiceCardArchive: () => import('@/components/layout/ServiceCardArchive'),
    RasterLayerTableChartButtons: () => import('@/components/utils/RasterLayerTableChartButtons'),
    MeteogramButton: () => import('@/components/utils/MeteogramButton')
  },
  props: {
    serviceSelected: {
      type: Array,
      default: () => []
    },
    serviceCardId: {
      type: String,
      default: ''
    },
    animationStepDateType: {
      type: String,
      default: ''
    },
    animationStep: {
      type: String,
      default: ''
    },
    animationLimitDateType: {
      type: String,
      default: ''
    },
    animationLimitFuture: {
      type: String,
      default: ''
    },
    animationLimitPast: {
      type: String,
      default: ''
    },
    refreshIntervalStep: {
      type: String,
      default: ''
    },
    refreshIntervalStepType: {
      type: String,
      default: ''
    },
    serviceName: {
      type: String,
      default: ''
    },
    isAnimation: {
      type: Boolean,
      default: false
    },
    isTable: {
      type: Boolean,
      default: false
    },
    isGraph: {
      type: Boolean,
      default: false
    },
    isMeteogram: {
      type: Boolean,
      default: false
    },
    meteogramEnabled: {
      type: Boolean,
      default: false
    },
    meteogramObj: {
      type: Object,
      default: () => {}
    },
    minStep: {
      type: Date,
      default: null
    },
    maxStep: {
      type: Date,
      default: null
    },
    rasterLayers: {
      type: Array,
      default: () => []
    },
    layerToSelectRaster: {
      type: Object,
      default: () => {}
    },
    animationLimitRangeArray: {
      type: Array,
      default: () => []
    },
    animationLimitRangeArrayPolicy: {
      type: Array,
      default: () => []
    },
    policyLimits: {
      type: Object,
      default: () => {}
    },
    checkPolicyLimits: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      overlay: false,
      renderArchiveDate: true,
      isRefreshing: false,
      refreshBtnDisabled: false,
      renderPlayer: false,
      noFeaturesFound: false,
      noWmtsFeaturesFound: false,
      mapEnabled: false,
      selectedRasterLayer: {},
      animationTimeArray: [],
      animationEmptySteps: [],
      animationStartIndex: 0,
      tableChartRangeDate: '',
      rotateEffect: '',
      runtime: ''
    };
  },
  computed: {
    ...mapState(['map', 'app_user', 'geoserver_data', 'table_chart']),
    disableRefreshButton() {
      const activeLayer = this.map.OLmap.getLayers().getArray().slice(1).some(layer => layer.values_.service_id === this.serviceCardId && layer.values_.layer_type !== 'METEOGRAM');

      return !activeLayer || this.isRefreshing || this.refreshBtnDisabled;
    },
    showSelect() {
      return this.mapEnabled || !this.isMeteogram;
    },
    dailyData() {
      return this.animationStepDateType === 'dynamic_months' || this.animationStepDateType === 'dynamic_days' || (this.animationStepDateType === 'dynamic_hours' && this.animationStep >= 24);
    }
  },
  watch: {
    $route() {},
    layerToSelectRaster: {
      async handler(newValue) {
        if (newValue && Object.keys(newValue).length !== 0) {
          const isDefaultService = this.app_user.defaultService.GLOBAL?.service_type === 'WS' && this.selectedRasterLayer.service_id !== this.layerToSelectRaster.service_id && this.app_user.defaultService.GLOBAL?.service_details.some(item => item.service_id === this.serviceCardId);

          const fromRoute = this.$route.params.id.replace('-', '');
          const isSpecificDefaultService = this.app_user.defaultService[fromRoute]?.service_type && this.selectedRasterLayer.service_id !== this.layerToSelectRaster.service_id && this.app_user.defaultService[fromRoute].service_details.some(item => item.service_id === this.serviceCardId) && this.map.specificDefaultService[fromRoute];

          const isSingleLayer = this.rasterLayers.length === 1;

          if (isDefaultService || isSpecificDefaultService || isSingleLayer) {
            this.selectedRasterLayer = this.layerToSelectRaster;

            if (isSpecificDefaultService && this.app_user.defaultService[fromRoute].service_details.some(item => item.service_id === this.serviceCardId && item.is_meteogram)) {
              this.$emit('toggle-meteogram-btn', true);
              this.$emit('check-specific-default-service');
            }

            if (Object.keys(this.selectedRasterLayer).length !== 0 && !this.map.OLmap.getLayers().getArray().slice(1).some(layer => layer.values_.service_id === this.layerToSelectRaster.service_id) && this.app_user.defaultService.GLOBAL?.service_path !== 'map/Archive' && this.$route.params.id !== 'Archive') {
              this.mapEnabled = true;

              this.openRasterLayer(true, this.selectedRasterLayer);

              if (isSpecificDefaultService) {
                this.$emit('check-specific-default-service');
              }
            }
          }
        }
      },
      immediate: true
    }
  },
  destroyed() {
    this.$emit('toggle-meteogram-btn', false);
  },
  methods: {
    ...mapActions(['loadRasterLayer', 'removeEveryRasterLayer', 'checkOpacityServiceID', 'resetAnimationTime', 'storeWsServices', 'getWmtsArray', 'getWmtsGDV', 'checkNewData', 'removeLayerByServiceID', 'getLayerExtent']),
    async openRasterLayer(isDefaultService, raster) {
      if (this.map.refreshIntervalID[raster.service_id] && !this.isRefreshing) {
        clearInterval(this.map.refreshIntervalID[raster.service_id]);
      }

      this.noFeaturesFound = false;
      this.renderPlayer = false;
      this.$nextTick(() => {
        this.renderPlayer = true;
      });
      this.overlay = true;
      await this.setAnimationSteps();

      if (!this.isRefreshing && this.map.isAnimating.length == 0) {
        this.resetAnimationTime();
      }

      if (this.animationTimeArray.length > 0) {
        this.map.sliderOpacityValue = 60;
        this.checkOpacityServiceID(this.serviceCardId);
        const previousRaster = this.map.OLmap.getLayers().getArray().find(layer => layer.values_.layer_type === 'RASTER' && layer.values_.service_id !== this.serviceCardId);

        if ((this.$route.params.id === 'Forecast' || this.$route.params.id === 'Real-Time') && previousRaster) {
          if (Object.keys(this.map.refreshIntervalID).includes(previousRaster.values_.service_id)) {
            clearInterval(this.map.refreshIntervalID[previousRaster.values_.service_id]);
            delete this.map.refreshIntervalID[previousRaster.values_.service_id];
          }
        }

        if (!this.isRefreshing) {
          this.setRefreshInterval();
        }

        this.removeEveryRasterLayer();

        this.geoserver_data.rasterLegend_selectedLabel = [];

        const extent = await this.getLayerExtent([raster.ws_name, raster.layer_name, raster.layer_type, raster.service_id]);
        const rasterParams = {
          url: `${RASTER_BASE_URL}/fe_ogc/geoserver/wms`,
          params: {
            ENV: '',
            GROUP: this.$route.params.id,
            LAYERS: `${raster.ws_name}:${raster.layer_name}`,
            TIME: this.animationTimeArray[this.animationStartIndex],
            STYLES: raster.layer_style,
            USERNAME: this.app_user.username,
            SERVICE_NAME: this.serviceName,
            FORMAT: 'image/vnd.jpeg-png8'
          },
          TYPE: 'RASTER',
          LAYER_SERVICE_ID: this.serviceCardId,
          EXTENT: extent
        };

        this.map.rasterMarkerDate = rasterParams.params.TIME;
        await this.loadRasterLayer(rasterParams);

        if (!isDefaultService) {
          if (this.app_user.defaultService.GLOBAL?.service_details && this.app_user.defaultService.GLOBAL?.service_details.length > 0) {
            const sliceIndex = this.app_user.defaultService.GLOBAL.service_details.findIndex(item => item.layer_type === 'RASTER');

            if (sliceIndex > -1) {
              if (this.map.layerToSelectRaster[this.app_user.defaultService.GLOBAL.service_details[sliceIndex].service_id]) {
                this.map.layerToSelectRaster[this.app_user.defaultService.GLOBAL.service_details[sliceIndex].service_id] = {};
              }

              this.app_user.defaultService.GLOBAL.service_details.splice(sliceIndex, 1);
            }
          }

          await this.$emit('closeRasterService', this.serviceCardId);
        }

        if (this.$route.params.id === 'Forecast') {
          this.renderPlayer = false;
          this.$nextTick(() => {
            this.renderPlayer = true;
          });
        }

        const unit = dateUnit(this.animationStepDateType);
        const centerCoordinates = toLonLat(this.map.OLmap.getView().getCenter());
        const timeStamp = Date.now();
        this.$gtag.event('Intervallo temporale', {
          event_category: `${this.$route.params.id == undefined ? 'Home' : this.$route.params.id} - ${this.serviceName}`,
          event_label: `${timeStamp} - ${this.app_user.username} - ${this.app_user.crm_id} - ${getFormattedDate(new Date(), true, true)}`,
          value: `${getFormattedDate(new Date(rasterParams.params.TIME), true)} - MIN: ${getFormattedDate(new Date(this.minStep), true)} MAX: ${getFormattedDate(new Date(this.maxStep), true)} STEP: ${this.animationStep}${unit}`
        });
        this.$gtag.event('Centroide mappa', {
          event_category: `${this.$route.params.id == undefined ? 'Home' : this.$route.params.id} - Lat ${centerCoordinates[1].toFixed(4)} - Lon ${centerCoordinates[0].toFixed(4)}`,
          event_label: `${timeStamp} - ${this.app_user.username} - ${this.app_user.crm_id} - ${getFormattedDate(new Date(), true, true)}`,
          value: `${this.serviceName}: ${this.selectedRasterLayer.layer_name} - ${getFormattedDate(new Date(rasterParams.params.TIME), true)}`
        });
      }

      this.overlay = false;

      if (!this.isRefreshing) {
        this.reloadLegend();
      }
    },
    reloadLegend() {
      this.map.reloadLegend = true;
      this.$nextTick(() => {
        this.map.reloadLegend = false;
      });
    },
    async removePreviousLayer() {
      if (this.$route.params.id === 'Real-Time' && this.map.OLmap.getLayers().getArray().slice(1).map(layer => layer.values_.service_id === this.serviceCardId)) {
        await this.removeLayerByServiceID(this.serviceCardId);
      }
    },
    removeRasterSelected() {
      this.renderArchiveDate = false;
      this.$nextTick(() => {
        this.renderArchiveDate = true;
      });

      if (this.$route.params.id === 'Forecast' || this.$route.params.id === 'Real-Time') {
        if (Object.keys(this.map.refreshIntervalID).includes(this.selectedRasterLayer.service_id)) {
          clearInterval(this.map.refreshIntervalID[this.selectedRasterLayer.service_id]);
          delete this.map.refreshIntervalID[this.selectedRasterLayer.service_id];
        }
      }

      this.selectedRasterLayer = {};
      this.removeEveryRasterLayer();
      this.reloadLegend();
    },
    async initRefresh() {
      this.isRefreshing = true;
      this.rotateEffect = 'rotating';
      this.refreshBtnDisabled = true;
      setTimeout(() => {
        this.rotateEffect = '';
        this.refreshBtnDisabled = false;
      }, 2000);

      this.$emit('set-refresh-interval-overlay', true);

      const currentLayer = {
        policy_id: this.selectedRasterLayer.policy_id,
        service_id: this.selectedRasterLayer.service_id,
        service_type: 'WS'
      };
      await this.storeWsServices(currentLayer);

      if (this.policyLimits.type != 'static') {
        this.$emit('reset-min-max-step');
      }

      await this.checkNewData(['time', `${this.selectedRasterLayer.ws_name}:${this.selectedRasterLayer.layer_name}`, this.animationTimeArray.at(-1)]);

      if (this.$route.params.id === 'Forecast') {
        this.runtime = await this.checkNewData(['RUNTIME', `${this.selectedRasterLayer.ws_name}:${this.selectedRasterLayer.layer_name}`, this.runtime]);
        this.map.runtime[this.selectedRasterLayer.service_id] = this.runtime;
      }

      const [isNewData, newDataTime] = this.geoserver_data.isNewData;
      const isRuntime = this.geoserver_data.isRuntime;

      const limit = this.$route.params.id === 'Real-Time'
        ? this.animationLimitRangeArrayPolicy.at(-1)
        : updatedDateWithTimeStep(this.animationLimitFuture, this.animationLimitDateType, this.$route.params.id, new Date());

      if ((isNewData && new Date(newDataTime) < new Date(limit)) || (this.$route.params.id === 'Forecast' && isRuntime)) {
        if (this.map.isAnimating.includes(this.serviceCardId)) {
          await this.setAnimationSteps();
        } else {
          await this.openRasterLayer(false, this.selectedRasterLayer);
        }
      }

      this.isRefreshing = false;
      this.$emit('set-refresh-interval-overlay', false);
    },
    setRefreshInterval() {
      const interval = timeToMilliseconds(this.refreshIntervalStep, this.refreshIntervalStepType);

      if (interval > 0) {
        this.map.refreshIntervalID[this.serviceCardId] = setInterval(async () => {
          await this.initRefresh();
        }, interval);
      }
    },
    async setAnimationSteps(userRangeDates) {
      const [archiveForecastInitialDate, archiveFinalDate] = userRangeDates || [];

      this.animationTimeArray = [];
      let wmtsTimeRange = '';
      const nowDate = new Date();
      const startTime = archiveForecastInitialDate || nowDate;
      const step = this.$route.params.id === 'Real-Time' ? this.animationLimitPast : this.animationLimitFuture;
      const otherBoundTime = this.$route.params.id === 'Archive' ? archiveFinalDate : updatedDateWithTimeStep(step, this.animationLimitDateType, this.$route.params.id, nowDate, true);

      wmtsTimeRange = this.$route.params.id === 'Real-Time'
        ? `${new Date(otherBoundTime).toISOString()}/${startTime.toISOString()}`
        : `${startTime.toISOString()}/${new Date(otherBoundTime).toISOString()}`;

      const minPolicy = this.animationLimitRangeArrayPolicy[0];
      let maxPolicy = this.animationLimitRangeArrayPolicy.at(-1);

      if (this.policyLimits.type != 'static') {
        maxPolicy = updatedDateWithTimeStep(this.policyLimits.future, this.policyLimits.type, 'add_step', new Date(), true);
      }

      const lowerBoundPolicy = new Date(minPolicy) < new Date(wmtsTimeRange.split('/')[0])
        ? wmtsTimeRange.split('/')[0]
        : new Date(minPolicy).toISOString();

      const upperBoundPolicy = new Date(maxPolicy) > new Date(wmtsTimeRange.split('/')[1])
        ? wmtsTimeRange.split('/')[1]
        : new Date(maxPolicy).toISOString();

      wmtsTimeRange = `${lowerBoundPolicy}/${upperBoundPolicy}`;

      const wmtsParams = {
        layer: `${this.selectedRasterLayer.ws_name}:${this.selectedRasterLayer.layer_name}`,
        time: wmtsTimeRange
      };
      await this.getWmtsArray(wmtsParams);

      const wmtsResultArray = [];
      const xml_document = (new window.DOMParser()).parseFromString(this.geoserver_data.xml_string, 'text/xml');
      xml_document.getElementsByTagName('DimensionDomain').forEach(item => {
        if (item.getElementsByTagName('Domain')[0].previousElementSibling.textContent === 'time') {
          if (item.getElementsByTagName('Domain')[0].textContent !== '') {
            wmtsResultArray.push(item.getElementsByTagName('Domain')[0].textContent);
          }
        }

        if (item.getElementsByTagName('Domain')[0].previousElementSibling.textContent === 'RUNTIME') {
          if (this.$route.params.id === 'Forecast') {
            this.runtime = item.getElementsByTagName('Domain')[0].textContent.split('--')[1];
            this.map.runtime[this.selectedRasterLayer.service_id] = this.runtime;
          }

          this.table_chart.isRuntimeDomain[this.serviceCardId] = [true, this.animationStep, this.animationStepDateType];
        }
      });

      if (wmtsResultArray.length > 0) {
        this.noWmtsFeaturesFound = false;
        this.noFeaturesFound = false;
        const [lowerBound, upperBound] = wmtsResultArray[0].split('--');
        this.tableChartRangeDate = `${lowerBound}/${upperBound}`;
        this.animationTimeArray.push(this.$route.params.id === 'Real-Time' ? upperBound : lowerBound);

        let dateToAdd = new Date(this.$route.params.id === 'Real-Time' ? upperBound : lowerBound).getTime();
        const limitDate = new Date(this.$route.params.id === 'Real-Time' ? lowerBound : upperBound).getTime();

        while (this.$route.params.id === 'Real-Time' ? (dateToAdd > limitDate) : (dateToAdd < limitDate)) {
          dateToAdd = updatedDateWithTimeStep(this.animationStep, this.animationStepDateType, this.$route.params.id, dateToAdd, true);
          this.animationTimeArray.push(new Date(dateToAdd).toISOString());
        }

        if (this.$route.params.id === 'Real-Time') {
          this.animationStartIndex = this.animationTimeArray.length - 1;
          this.animationTimeArray = this.animationTimeArray.reverse();
        }

        this.animationStartIndex = this.$route.params.id === 'Real-Time' ? this.animationTimeArray.length - 1 : 0;
        await this.getDataAvailability();
      } else {
        if (this.$route.params.id === 'Forecast') {
          await this.removeLayerByServiceID(this.serviceCardId);
        }

        wmtsTimeRange = null;
        this.noWmtsFeaturesFound = true;
        this.noFeaturesFound = true;
      }
    },
    async getDataAvailability() {
      const animationStepLength = this.animationTimeArray.length;

      if (animationStepLength > 0) {
        let time = new Date(this.animationTimeArray[0]);
        time = time.setTime(time.getTime() - 60000);

        await this.getWmtsGDV([`${this.selectedRasterLayer.ws_name}:${this.selectedRasterLayer.layer_name}`, new Date(time).toISOString(), this.$route.params.id === 'Forecast' ? undefined : animationStepLength]);

        const xml_document = (new window.DOMParser()).parseFromString(this.geoserver_data.xml_string_GDV, 'text/xml');
        const xml_content = xml_document.getElementsByTagName('Domain')[0].textContent;
        let wmtsResultArray = xml_content ? xml_content.split(',').sort() : [];

        const max = wmtsResultArray.find(time => new Date(time).getTime() > new Date(this.animationTimeArray[animationStepLength - 1]).getTime());

        if (max) {
          const index = wmtsResultArray.indexOf(max);
          wmtsResultArray = wmtsResultArray.slice(0, index);
        }

        this.animationEmptySteps = [];

        if (animationStepLength !== wmtsResultArray.length) {
          this.animationTimeArray.forEach(step => {
            if (!wmtsResultArray.some(time => time === step)) {
              this.animationEmptySteps.push(step);
            }
          });
        }
      }
    },
    noRasterFeaturesFound(bool) {
      this.noFeaturesFound = bool;
    },
    toggleMapBtn() {
      this.mapEnabled = !this.mapEnabled;

      if (!this.mapEnabled) {
        this.removeRasterSelected();
        this.renderPlayer = false;
      }
    },
    googleTag() {
      this.$gtag.event('Variabile', {
        event_category: `${this.$route.params.id == undefined ? 'Home' : this.$route.params.id}`,
        event_label: `${Date.now()} - ${this.app_user.username} - ${this.app_user.crm_id} - ${getFormattedDate(new Date(), true, true)}`,
        value: `${this.serviceName}: ${this.selectedRasterLayer.layer_name}`
      });
    }
  }
};
</script>

<style scoped>
@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rotating {
  -webkit-animation: rotating 1s linear infinite;
  -moz-animation: rotating 1s linear infinite;
  -ms-animation: rotating 1s linear infinite;
  -o-animation: rotating 1s linear infinite;
  animation: rotating 1s linear infinite;
}
</style>

